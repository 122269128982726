import { useState } from 'react';
import {
  useExportMenu,
  useExportPosData,
  useRefundOrderItem,
  useOrders,
  useSendMenu,
  useRevokeOrderItemRefund,
  useExportOrders,
  useUpdateOrderItem,
} from '../utils/api.hook';
import { OrdersComponent } from './orders.component';
import { Dayjs } from 'dayjs';
import { FilterValue } from 'antd/es/table/interface';
import { usePagination } from '../shared/usePagintation';

export function OrdersContainer() {
  const pagination = usePagination();
  const [timeRangeExportOrders, setTimeRangeExportOrders] = useState<
    [Dayjs, Dayjs] | undefined
  >(undefined);
  const [timeRange, setTimeRange] = useState<{
    startTime: Dayjs | undefined;
    endTime: Dayjs | undefined;
  }>({
    startTime: undefined,
    endTime: undefined,
  });
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>();
  const [exportDate, setExportDate] = useState<Dayjs | undefined>(undefined);
  const [exportLocationIds, setExportLocationIds] = useState<string[]>([]);
  const [exportLayoutType, setExportLayoutType] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const { mutateAsync: sendMenu } = useSendMenu();
  const { refetch: exportMenu, isLoading: isExporting } = useExportMenu({
    date: exportDate,
  });
  const { refetch: exportPosData, isLoading: isExportingPosData } =
    useExportPosData({
      date: exportDate,
      locationIds: exportLocationIds,
      layoutType: exportLayoutType,
    });
  const {
    data: {
      orders,
      addressFilters,
      total,
      redeemTimeFilters,
      providerNameFilters,
    } = {},
    isFetching,
    isLoading,
  } = useOrders({
    ...timeRange,
    ...filters,
    keyword,
    skip: pagination.skip,
    take: pagination.take,
  });
  const { mutateAsync: refundOrderItem, isLoading: isRefunding } =
    useRefundOrderItem();
  const { mutateAsync: revokeOrderItemRefund, isLoading: isRevertingRefund } =
    useRevokeOrderItemRefund();
  const { isLoading: isOrderExporting, isFetching: isOrderFetching } =
    useExportOrders({
      timeRange: timeRangeExportOrders,
    });
  const { mutateAsync: updateOrderItem, isLoading: isUpdatingOrderItem } =
    useUpdateOrderItem();

  const exportOrders = async (timeRange: [Dayjs, Dayjs]) => {
    setTimeRangeExportOrders(timeRange);

    setTimeout(() => {
      setTimeRangeExportOrders(undefined);
    }, 1);
  };

  return (
    <OrdersComponent
      addressFilters={addressFilters}
      exportMenu={exportMenu}
      exportOrders={exportOrders}
      exportPosData={exportPosData}
      orders={orders}
      providerNameFilters={providerNameFilters}
      redeemTimeFilters={redeemTimeFilters}
      refundOrderItem={refundOrderItem}
      revokeRefund={revokeOrderItemRefund}
      sendMenu={sendMenu}
      setExportDate={setExportDate}
      setExportLocationIds={setExportLocationIds}
      setExportLayoutType={setExportLayoutType}
      setFilters={setFilters}
      setKeyword={setKeyword}
      setTimeRange={setTimeRange}
      updateOrderItem={updateOrderItem}
      pagination={{
        ...pagination,
        total,
      }}
      isLoading={
        isLoading ||
        isExporting ||
        isExportingPosData ||
        isRefunding ||
        isRevertingRefund ||
        isFetching ||
        isOrderExporting ||
        isOrderFetching ||
        isUpdatingOrderItem
      }
    />
  );
}
