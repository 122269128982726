import { useMemo } from 'react';
import {
  Row,
  Col,
  Typography,
  Button,
  List,
  Card,
  Space,
  Flex,
  Alert,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { isEmpty, keys, map } from 'lodash';
import schedules from '../schedules';

const WEEK_TRANSLATE = ['日', '一', '二', '三', '四', '五', '六'];

type CalendarHeaderProps = {
  value: Dayjs;
  onChange(date: Dayjs): void;
};

export function CalendarHeader({ value, onChange }: CalendarHeaderProps) {
  return (
    <Space align="baseline">
      <Row gutter={8}>
        <Col>
          <Button
            size="small"
            onClick={() => {
              onChange(value.subtract(14, 'days'));
            }}
          >
            上兩週
          </Button>
        </Col>
        <Col>
          <Button
            size="small"
            onClick={() => {
              onChange(value.add(14, 'days'));
            }}
          >
            下兩週
          </Button>
        </Col>
      </Row>
    </Space>
  );
}

type CalendarCellProps = {
  listData: schedules.MappingGroupByTimestamp;
  locationTagName: string;
  locationTagId: string;
  renderListData: Function;
  noteData: { [time: number]: { [locationTagId: string]: string } };
  onClickView: Function;
};

export function CalendarCell({
  listData,
  locationTagName,
  locationTagId,
  renderListData,
  noteData,
  onClickView,
}: CalendarCellProps) {
  const allTimestamp = useMemo(() => keys(listData), [listData]);

  return (
    <Card>
      <Typography.Title level={5} className="location-tag-title">
        {locationTagName}
      </Typography.Title>

      <div>
        {map(allTimestamp, (timestamp: number) => {
          timestamp = Number(timestamp);
          const note = noteData?.[timestamp]?.[locationTagId];

          return (
            <List.Item key={timestamp}>
              <Flex
                justify="space-between"
                align="center"
                style={{ width: '100%' }}
              >
                <Typography.Text strong>
                  {dayjs(timestamp).format('YYYY-MM-DD')} (
                  {WEEK_TRANSLATE[dayjs(timestamp).get('day')]})
                </Typography.Text>

                {!isEmpty(listData[timestamp]) && (
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => onClickView(timestamp)}
                  ></Button>
                )}
              </Flex>

              {note && (
                <Alert
                  style={{ width: '100%' }}
                  message={note}
                  type="info"
                  showIcon
                />
              )}

              {renderListData(
                listData || Array<schedules.CellData>(),
                timestamp
              )}
            </List.Item>
          );
        })}
      </div>
    </Card>
  );
}
