import { Button, DatePicker, Input, Space, Table, Upload, message } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import dayjs, { Dayjs } from 'dayjs';
import { debounce, get, includes, map, orderBy } from 'lodash';
import locale from 'antd/es/date-picker/locale/zh_TW';
import 'dayjs/locale/zh-tw';
import {
  ColumnType,
  FilterValue,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import useModal from 'antd/es/modal/useModal';
import { SendMenuFormComponent } from './send-menu-form.component';
import { useForm } from 'antd/es/form/Form';
import { useQueryClient } from 'react-query';
import { ExportMenuFormComponent } from './export-menu-form.component';
import { ExportPosDataComponent } from './export-pos-data-form.component';
import { RefundFormComponent } from './refund-form.component';
import { useCallback } from 'react';
import { useAuthStore } from '../utils/stores/useAuthStore';
import { ExportOrdersFormComponent } from './export-orders-form.component';
import { SendMenuDto, UpdateOrderItemDto } from '../utils/api-client';
import { UpdateOrderItemFormComponent } from './update-order-item-form.component';

type Props = {
  addressFilters: string[];
  exportMenu: () => void;
  exportOrders: (timeRange: [Dayjs, Dayjs]) => void;
  exportPosData: () => void;
  isLoading: boolean;
  orders: any[];
  pagination: TablePaginationConfig;
  providerNameFilters: string[];
  redeemTimeFilters: string[];
  revokeRefund: (id: string) => void;
  sendMenu: (data: SendMenuDto) => Promise<any>;
  setExportDate: (date: Dayjs) => void;
  setExportLocationIds: (ids: string[]) => void;
  setExportLayoutType: (layoutType: string) => void;
  setFilters: (data: Record<string, FilterValue | null>) => void;
  setKeyword: (key: string) => void;
  updateOrderItem: (data: UpdateOrderItemDto & { id: string }) => Promise<any>;
  setTimeRange: (data: {
    startTime: Dayjs | undefined;
    endTime: Dayjs | undefined;
  }) => void;
  refundOrderItem: (data: {
    id: string;
    amount: number;
    reason: string;
    note: string;
    fee: number;
  }) => void;
};

export function OrdersComponent({
  addressFilters,
  exportMenu,
  exportOrders,
  exportPosData,
  isLoading,
  orders,
  pagination,
  providerNameFilters,
  redeemTimeFilters,
  refundOrderItem,
  revokeRefund,
  sendMenu,
  setExportDate,
  setExportLocationIds,
  setExportLayoutType,
  setFilters,
  setKeyword,
  setTimeRange: changeTimeRange,
  updateOrderItem,
}: Props) {
  const queryClient = useQueryClient();
  const [modal, contextHolder] = useModal();
  const [form] = useForm();
  const token = useAuthStore((state) => state.token);
  const onImportButtonClick = () => {};
  const onRangePickerChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    changeTimeRange({
      startTime: dates?.[0] || undefined,
      endTime: dates?.[1] || undefined,
    });
  };

  const onExportOrderDetailButtonClick = () => {
    modal.confirm({
      cancelText: '取消',
      content: <ExportOrdersFormComponent form={form} />,
      icon: null,
      okText: '匯出',
      title: '匯出訂單明細',
      onOk: () => {
        const values = form.getFieldsValue();

        const { timeRange } = values;
        const [startTime, endTime] = timeRange;

        return exportOrders([startTime.startOf('date'), endTime.endOf('date')]);
      },
    });
  };

  const onEditButtonClick = (orderItem: Props['orders'][number]) => {
    const m = modal.info({
      title: '編輯訂單',
      closable: true,
      content: (
        <UpdateOrderItemFormComponent
          data={orderItem}
          onFinish={async (values) => {
            await updateOrderItem({
              id: orderItem.id,
              ...values,
            });

            m.destroy();
          }}
        />
      ),
      icon: null,
      okButtonProps: {
        style: { display: 'none' },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onKeywordInputChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value);
    }, 500),
    [setKeyword]
  );

  const onRefundButtonClick = (data: Props['orders'][number]) => {
    modal.confirm({
      cancelText: '取消',
      content: (
        <RefundFormComponent
          data={
            {
              ...data,
              ...data.product,
              ...data.refund,
            } || {}
          }
          form={form}
        />
      ),
      icon: null,
      okText: '退款',
      title:
        '商品退款 - ' + data.phone + ' ' + data.providerName + ' ' + data.name,
      onOk: async () => {
        const values = await form.validateFields();

        return refundOrderItem({
          amount: values.amount,
          fee: values.fee,
          id: data.id,
          note: values.note,
          reason: values.reason,
        });
      },
    });
  };

  const onRevokeRefundButtonClick = (data: Props['orders'][number]) => {
    modal.confirm({
      cancelText: '取消',
      content: '確定要撤銷退款？',
      icon: null,
      okText: '撤銷',
      title: '撤銷退款',
      onOk: () => {
        return revokeRefund(data.id);
      },
    });
  };

  const onChange = ({ file, fileList }: UploadChangeParam) => {
    if (file.status === 'done') {
      message.success(`上傳成功`);
      queryClient.invalidateQueries(['orders']);
    } else if (file.status === 'error') {
      message.error(file.response?.message, 20);
    }
  };

  const onSendMenuButtonClick = () => {
    modal.confirm({
      cancelText: '取消',
      content: <SendMenuFormComponent form={form} />,
      icon: null,
      okText: '確定',
      title: '發送菜單',
      onOk: async () => {
        const values = await form.validateFields();

        return sendMenu(values);
      },
    });
  };

  const onExportMenuButtonClick = () => {
    modal.confirm({
      cancelText: '取消',
      content: <ExportMenuFormComponent form={form} />,
      icon: null,
      okText: '匯出',
      title: '匯出發餐單',
      onOk: async () => {
        const values = await form.validateFields();

        const { date } = values;

        setExportDate(date);

        return new Promise((res) => {
          setTimeout(async () => {
            await exportMenu();
            res(true);
          }, 100);
        });
      },
    });
  };

  const onExportPosDataButtonClick = () => {
    modal.confirm({
      cancelText: '取消',
      content: <ExportPosDataComponent form={form} />,
      icon: null,
      okText: '匯出',
      title: '匯出 POS 單',
      onOk: async () => {
        const values = await form.validateFields();

        const { date, locationIds, layoutType } = values;

        setExportDate(date);
        setExportLocationIds(locationIds);
        setExportLayoutType(layoutType);

        return new Promise((res) => {
          setTimeout(async () => {
            await exportPosData();
            res(true);
          }, 100);
        });
      },
    });
  };

  const columns: ColumnType<Props['orders'][number]>[] = [
    {
      title: '訂單編號',
      dataIndex: 'id',
    },
    {
      title: '狀態',
      dataIndex: 'state',
      filters: [
        { text: '已確認', value: '已確認' },
        { text: '未確認', value: '未確認' },
        { text: '取消訂單', value: '取消訂單' },
        { text: '取消訂單-退貨', value: '取消訂單-退貨' },
      ],
    },
    {
      title: '訂單日期',
      dataIndex: 'time',
      width: 100,
      render: (time: string) => new Date(time).toLocaleDateString(),
    },
    {
      title: '取貨日期',
      width: 100,
      filters: map(
        orderBy(redeemTimeFilters, undefined, 'desc'),
        (redeemTime) => ({
          text: new Date(redeemTime).toLocaleDateString(),
          value: redeemTime,
        })
      ),
      dataIndex: 'redeemTime',
      render: (time: string) => new Date(time).toLocaleDateString(),
    },
    {
      title: '客戶名字',
      dataIndex: 'customerName',
    },
    {
      title: '客戶電話',
      dataIndex: 'phone',
    },
    {
      title: '供應商',
      dataIndex: 'providerNames',
      filters: map(providerNameFilters, (providerName) => ({
        text: providerName,
        value: providerName,
      })),
      filterSearch: true,
      render: (v, record) => record.providerName,
    },
    {
      title: '品名',
      dataIndex: 'name',
    },
    {
      title: '規格',
      dataIndex: 'specification',
    },
    {
      title: '地點',
      dataIndex: 'address',
      filterSearch: true,
      filters: map(addressFilters, (address) => ({
        text: address,
        value: address,
      })),
    },
    {
      title: '單價',
      dataIndex: 'price',
    },
    {
      title: '數量',
      dataIndex: 'quantity',
    },
    {
      title: '總金額',
      render: (record) => record.price * record.quantity,
    },
    {
      title: '操作',
      render: (record) => {
        const amount = get(record, 'refund.amount', 0);
        const fee = get(record, 'refund.fee', 0);

        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                onEditButtonClick(record);
              }}
            >
              編輯
            </Button>
            <Button
              type="primary"
              size="small"
              danger
              disabled={
                !includes(['已確認', '取消訂單', '取消訂單-退貨'], record.state)
              }
              onClick={() => onRefundButtonClick(record)}
            >
              退款{amount || fee ? `(${amount + fee})` : ''}
            </Button>
            {record.refund && (
              <Button
                type="primary"
                size="small"
                onClick={() => onRevokeRefundButtonClick(record)}
              >
                撤銷退款
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Space direction="vertical" className="w-full">
      {contextHolder}
      <div className="text-2xl font-bold">訂單列表</div>
      <Table
        dataSource={orders}
        loading={isLoading}
        rowKey="id"
        size="small"
        title={() => (
          <Space direction="vertical" size="large">
            <Space>
              <Upload
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                action={`${process.env.REACT_APP_API_SERVER_URL}/orders/import`}
                headers={{
                  Authorization: `Bearer ${token}`,
                }}
                name="file"
                onChange={onChange}
                showUploadList={false}
              >
                <Button type="primary" onClick={onImportButtonClick}>
                  匯入訂單
                </Button>
              </Upload>
              <Button type="primary" onClick={onSendMenuButtonClick}>
                發送菜單
              </Button>
              <Button type="primary" onClick={onExportMenuButtonClick}>
                匯出發餐單
              </Button>
              <Button type="primary" onClick={onExportPosDataButtonClick}>
                匯出 POS 單
              </Button>
              <Button type="primary" onClick={onExportOrderDetailButtonClick}>
                匯出訂單明細
              </Button>
            </Space>
            <div className="flex items-center gap-8">
              <Space>
                <span>訂單日期</span>
                <DatePicker.RangePicker
                  presets={[
                    {
                      label: '今天',
                      value: [dayjs().startOf('date'), dayjs().endOf('date')],
                    },
                    {
                      label: '昨天',
                      value: [
                        dayjs().startOf('date').subtract(1, 'day'),
                        dayjs().endOf('date').subtract(1, 'day'),
                      ],
                    },
                    {
                      label: '本週',
                      value: [dayjs().startOf('week'), dayjs().endOf('week')],
                    },
                    {
                      label: '上週',
                      value: [
                        dayjs().startOf('week').subtract(1, 'week'),
                        dayjs().endOf('week').subtract(1, 'week'),
                      ],
                    },
                    {
                      label: '本月',
                      value: [dayjs().startOf('month'), dayjs().endOf('month')],
                    },
                    {
                      label: '前月',
                      value: [
                        dayjs().startOf('month').subtract(1, 'month'),
                        dayjs().endOf('month').subtract(1, 'month'),
                      ],
                    },
                  ]}
                  onChange={onRangePickerChange}
                  locale={locale}
                />
              </Space>
              <div className="flex items-center gap-2 w-80">
                <span className="shrink-0">關鍵字</span>
                <Input
                  className="grow"
                  placeholder="請輸入客戶名字、客戶電話或是品名"
                  onChange={onKeywordInputChange}
                />
              </div>
            </div>
          </Space>
        )}
        pagination={pagination}
        columns={columns}
        scroll={{ x: 'max-content' }}
        onChange={(pagination, filters, sorter, extra) => {
          setFilters(filters);
        }}
      />
    </Space>
  );
}
