import { Button, Space, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import useModal from 'antd/es/modal/useModal';
import { ColumnType } from 'antd/es/table';
import { LocationTagForm } from './location-tag-form.component';
import { map } from 'lodash';
import { Color } from 'antd/es/color-picker/color';

type Props = {
  isLoading: boolean;
  locationTags: {
    id: string;
    name: string;
    description: string;
    pay: number;
    locations: {
      name: string;
      idNumber: string;
      id: string;
    }[];
    textColor: string;
    backgroundColor: string;
  }[];
  createLocationTag: (values: {
    name: string;
    description: string;
    textColor: string;
    backgroundColor: string;
    pay: number;
  }) => Promise<any>;
  updateLocationTag: (values: {
    id: string;
    name: string;
    description: string;
    textColor: string;
    backgroundColor: string;
    pay: number;
  }) => Promise<any>;
  deleteLocationTag: (id: string) => Promise<any>;
};

export function LocationsTagsComponent(props: Props) {
  const [form] = useForm<{
    name: string;
    description: string;
    locationIds: string[];
    textColor: Color;
    backgroundColor: Color;
    pay: number;
  }>();
  const [modal, contextHolder] = useModal();

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: '取消',
      content: <LocationTagForm form={form} />,
      icon: null,
      okText: '確認',
      title: '新增大樓標籤',
      onOk: async () => {
        const values = await form.validateFields();

        return props.createLocationTag({
          ...values,
          textColor: values.textColor.toHexString(),
          backgroundColor: values.backgroundColor.toHexString(),
        });
      },
    });
  };

  const onEditButtonClick = (data: Props['locationTags'][number]) => {
    modal.confirm({
      cancelText: '取消',
      content: <LocationTagForm form={form} data={data} />,
      icon: null,
      okText: '確認',
      title: '編輯大樓標籤',
      onOk: async () => {
        const values = await form.validateFields();

        return props.updateLocationTag({
          ...values,
          id: data.id,
          textColor: values.textColor.toHexString(),
          backgroundColor: values.backgroundColor.toHexString(),
        });
      },
    });
  };

  const onDeleteButtonClick = (id: string) => {
    modal.confirm({
      cancelText: '取消',
      content: '確定要刪除嗎？',
      icon: null,
      okText: '確認',
      title: '刪除大樓標籤',
      onOk: async () => {
        return props.deleteLocationTag(id);
      },
    });
  };

  const columns: ColumnType<Props['locationTags'][number]>[] = [
    {
      title: '名稱',
      dataIndex: 'name',
    },
    {
      title: '敘述',
      dataIndex: 'description',
    },
    {
      title: '費用',
      dataIndex: 'pay',
    },
    {
      align: 'center',
      dataIndex: 'textColor',
      key: 'textColor',
      title: '文字顏色',
      width: 80,
      render: (backgroundColor) => (
        <div
          className="inline-block w-6 h-6 rounded-full"
          style={{ backgroundColor, border: '1px solid #000000' }}
        />
      ),
    },
    {
      align: 'center',
      dataIndex: 'backgroundColor',
      key: 'backgroundColor',
      title: '背景顏色',
      width: 80,
      render: (backgroundColor) => (
        <div
          className="inline-block w-6 h-6 rounded-full"
          style={{ backgroundColor, border: '1px solid #000000' }}
        />
      ),
    },
    {
      title: '套用的大樓',
      dataIndex: 'locations',
      render: (locations) => {
        return (
          <Space direction="vertical" size="small">
            {map(locations, (location) => {
              return (
                <div key={location.id}>
                  {location.idNumber} - {location.name}
                </div>
              );
            })}
          </Space>
        );
      },
    },
    {
      title: '操作',
      width: 100,
      render: (record) => (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              onEditButtonClick(record);
            }}
          >
            編輯
          </Button>
          <Button
            danger
            size="small"
            onClick={() => {
              onDeleteButtonClick(record.id);
            }}
          >
            刪除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space className="w-full" direction="vertical">
      {contextHolder}
      <div className="text-2xl font-bold">大樓標籤</div>
      <Table
        columns={columns}
        loading={props.isLoading}
        scroll={{ x: 'max-content' }}
        size="small"
        rowKey="id"
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            建立標籤
          </Button>
        )}
        dataSource={props.locationTags || []}
      ></Table>
    </Space>
  );
}
