// 匯出菜單表單元件
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect } from 'react';

type Props = {
  form: FormInstance;
  driverOptions: {
    label: string;
    value: string;
  }[];
};

export function SalaryFormComponent(props: Props) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form}>
      <Form.Item name="driverIds" label="司機">
        <Select
          options={props.driverOptions}
          mode="multiple"
          placeholder="沒有選擇的話代表全部"
          optionFilterProp="label"
          showSearch
        />
      </Form.Item>
    </Form>
  );
}
