import { Form, FormInstance, DatePicker, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect } from 'react';
import dayjs from 'dayjs';

type Props = {
  form: FormInstance;
  driverOptions: { label: string; value: string }[];
};

export function SendDriverTicketFormComponent(props: Props) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} initialValues={{ time: dayjs() }}>
      <FormItem
        label="日期"
        name="time"
        rules={[{ required: true, message: '請選擇日期' }]}
      >
        <DatePicker />
      </FormItem>
      <Form.Item
        name="driverIds"
        label="司機"
        rules={[
          {
            required: true,
            message: '請選擇司機',
          },
        ]}
      >
        <Select
          options={props.driverOptions}
          mode="multiple"
          placeholder="沒有選擇的話代表全部"
          optionFilterProp="label"
          showSearch
        />
      </Form.Item>
    </Form>
  );
}
