import { Form, FormInstance, DatePicker } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect } from 'react';
import { Dayjs } from 'dayjs';

type Props = {
  dateRange: [Dayjs, Dayjs];
  form: FormInstance;
};

export function ExportDriverTicketFormComponent(props: Props) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} initialValues={{ dateRange: props.dateRange }}>
      <FormItem
        label="日期區間"
        name="dateRange"
        rules={[{ required: true, message: '請選擇日期區間' }]}
      >
        <DatePicker.RangePicker />
      </FormItem>
    </Form>
  );
}
