import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_TW';
import { FormInstance } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect } from 'react';

export function DriverEventForm(props: {
  form: FormInstance;
  data?: {
    driver: {
      id: string;
      idNumber: string;
      name: string;
    };
  };
  driverOptions: { label: string; value: string }[];
}) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item
        label="時間範圍"
        name="time"
        rules={[{ required: true, message: '請選擇時間範圍' }]}
      >
        <DatePicker.RangePicker
          locale={locale}
          disabledDate={(current) => current && current > dayjs().endOf('day')}
        />
      </Form.Item>
      <Form.Item
        label="司機"
        name="driverId"
        rules={[{ required: true, message: '請選擇司機' }]}
      >
        <Select
          placeholder="請選擇司機"
          options={props.driverOptions}
          optionFilterProp="label"
          showSearch
        />
      </Form.Item>
      <Form.Item
        label="調整金額"
        name="amount"
        rules={[{ required: true, message: '請輸入調整金額' }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="備註"
        name="note"
        rules={[{ required: true, message: '請輸入備註' }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
